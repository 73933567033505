import React, { Component } from 'react';
import './ExperienceCard.css';
import { Fade } from "react-reveal";

class ExperienceCard extends Component {
  render() {
    const experience = this.props.experience;
    const index = this.props.index;
    const totalCards = this.props.totalCards;
    const theme = this.props.theme;
    return (
      <div className="experience-list-item" style={{ marginTop: (index === 0 ? 30 : 50) }}>
        <Fade left duration={2000} distance="40px">
          <div className="experience-card-logo-div">
            <img
              className="experience-card-logo"
              src={require(`../../assests/images/${experience["logo_path"]}`)}
              alt=""
            />
          </div>
        </Fade>
        <div className="experience-card-stepper">
          <div style={{ width: 20, height: 20, backgroundColor: `${theme.headerColor}`, borderRadius: 50, zIndex: 100 }} />
          {
            index !== (totalCards - 1) &&
            <div style={{ height: 380, width: 2, backgroundColor: `${theme.headerColor}`, position: 'absolute', marginTop: 20 }} />
          }
        </div>
        <Fade right duration={2000} distance="40px">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="arrow-left" style={{ borderRight: `10px solid ${theme.body}` }}></div>
            <div className="experience-card" style={{ background: `${theme.body}` }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                  <h3 className="experience-card-title" style={{ color: theme.text }}>{experience["title"]}</h3>
                  <p className="experience-card-company" style={{ color: theme.text }}>
                    <a href={experience["company_url"]} target="_blank" rel="noopener noreferrer">
                      {experience["company"]}
                    </a>
                  </p>
                </div>
                <div>
                  <div className="experience-card-heading-right">
                    <p className="experience-card-duration" style={{ color: theme.secondaryText }}>{experience["duration"]}</p>
                    <p className="experience-card-location" style={{ color: theme.secondaryText }}>{experience["location"]}</p>
                  </div>

                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
                <div
                  className="repo-description"
                />
                {/* {experience["description"]} */}
                {index == 0 && 
                  <p> 知人の紹介より 自社ノートPCブランドを持つ年商50億円規模の会社より、ECサイトモール参入に伴う、動画投稿・オンラインショッピング・ライブ配信機能を持つアプリの開発依頼を受けました。<br/>
                  過去のアプリ開発経験を活かし、初めからクロスプラットフォームを意識した開発を提案しまして、FlutterとReact Nativeを検証した後に、Flutterを採用しました。アプリの主要機能はFlutterで実装、一方、動画フィルター機能はObjective-CおよびJavaを用いて開発を進めました。<br/> 
                  アプリの主要機能、バックエンドAPIや運営用の管理画面の開発、さらにAWSによるインフラ構築を全て担当いたしました。 <br/>
                  主要機能： <br/>
                  ・フィルターを使用しての動画投稿 <br/>
                  ・標準的なソーシャル機能（コメント・いいね・チャット） <br/>
                  ・リール式の動画閲覧 <br/>
                  ・標準的なECショッピング機能 <br/>
                  ・ライブ配信 <br/>
                  ・運営用管理画面 </p>
                }
                {index == 1 && 
                  <p>フリーランスとして独立を目指し、オンラインで会員の音声を解析し、専門家にアドバイスを求めるWebシステムの開発案件を受けました。
                  正社員を退職後、3人のシステムエンジニアとともにチームを組成。約1年間の開発期間を経て、システムをリリースすることができました。現在サイトは問題なく運営中です。<br/>

                  アプリ基幹機能の先行構築及びAWSインフラは自分が担当しました。<br/>
                  
                  ・会員の招待・登録<br/>
                  ・オンライン式の音声解析、オンラインコミュニケーション<br/>
                  ・マスター管理画面</p>
                }
                {index == 2 && 
                  <p>
                    知人の紹介を通じて、都内の広告代理事業に特化していた会社がピーク時に月間10億回PVを記録したポータルサイトを買収しました。<br/> 
                    これを受けて、新たにサイト運営部門とシステム開発部を設立。私はこの新設された部門の係長として、そのシステム開発課初めての正社員として参画しました。<br/> 
                    最初のタスクとして、さくらインターネットの専用サーバーからAWSへの移行を担当し、その後、サイトのリニューアル、ポイントシステムアプリの開発、およびiOSとAndroidでのアプリ開発と保守を実施しました<br/> 

                    専用サーバからAWSインフラの移転・保守、アプリの新規開発及びバックエンドの構築作業を担当しました。<br/> 

                    ・ユーザー用アプリ（Android,iOS）<br/> 
                    ・店舗用アプリ（Android）<br/> 
                    ・管理システム（Web）<br/> 
                  </p>
                }
                {index == 3 && 
                  <p>
                    iPhone4の発売後、初めてスマートフォンを手に取ったことで、モバイルアプリの将来性を強く感じました。<br/> 
                    その確信から、アプリ開発に特化した環境を求め、アプリ開発を専念できる会社へ転職を決意しました。入社後、2年間富士通へ派遣され、アプリの開発の詳細設計およびコーディングを担当しました。<br/> 

                    ・地域の見守りネットワークに活用されているアプリ、
                  </p>
                }
                {index == 4 && 
                  <p>
                    専門はソフトウェア工学で、簿記の資格ももっているため、それを活かして経理システムの開発に従事する企業に入社しました。開発チームの一員として、詳細設計からの工程を担当してました。<br/> 

                    《担当業務》<br/> 
                    　仕訳帳作成、消費税集計、消費税申告書作成<br/> 

                    技術<br/> 
                    Java Struts、Oracle<br/> 
                  </p>
                }

              </div>

            </div>

          </div>
        </Fade>
      </div>

    );
  }
}

export default ExperienceCard;
