import React, { Component } from "react";
import "./SkillCard.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Fade } from "react-reveal";

class SkillCard extends Component {
  render() {
    const pullRequest = this.props.pullRequest;
    var iconPR;
    var bgColor;
    if (pullRequest["state"] === "OPEN") {
      iconPR = {
        iconifyClass: "octicon:git-pull-request",
        style: { color: "#28a745" },
      };
      bgColor = "#dcffe4";
    } else if (pullRequest["state"] === "MERGED") {
      iconPR = {
        iconifyClass: "octicon:git-merge",
        style: { color: "#6f42c1" },
      };
      bgColor = "#f5f0ff";
    } else {
      iconPR = {
        iconifyClass: "octicon:git-pull-request",
        style: { color: "#d73a49" },
      };
      bgColor = "#ffdce0";
    }

    var subtitleString =
      "#" +
      pullRequest["number"] +
      " opened on " +
      pullRequest["createdAt"].split("T")[0];
    var mergedBy;
    if (pullRequest["mergedBy"] !== null) {
      const name = pullRequest["mergedBy"]["login"];
      mergedBy = (
        <OverlayTrigger
          key={name}
          placement={"top"}
          style={{ marginBottom: "5px" }}
          overlay={
            <Tooltip id={`tooltip-top`}>
              <strong>{`Merged by ${name}`}</strong>
            </Tooltip>
          }
        >
          <a
            href={pullRequest["mergedBy"]["url"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="merge-by-img"
              src={pullRequest["mergedBy"]["avatarUrl"]}
              alt=""
            />
          </a>
        </OverlayTrigger>
      );
    } else {
      mergedBy = <noscript></noscript>;
    }

    return (
      <Fade bottom duration={2000} distance="40px">
        <div
          className="pull-request-card"
          style={{
            backgroundColor: bgColor,
            border: `1px solid ${iconPR.style.color}`,
          }}
        >
          {pullRequest["number"] == 1 &&
            <div className="skill-category">
              <h2>フロントエンド</h2>
              <ul>
                <li>
                  <span className="skill-name">Swift</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">Flutter/Dart</span>
                  <span className="experience">4年</span>
                  <span className="rating">★★★★☆</span>
                </li>
                
                
                <li>
                  <span className="skill-name">AndroidJava</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">Objective-C</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
                <li>
                  <span className="skill-name">JavaScript/TypeScript</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
                
              </ul>
            </div>
        }
        {pullRequest["number"] == 2 &&
            <div className="skill-category">
              <h2>バックエンド</h2>
              <ul>
                <li>
                  <span className="skill-name">PHP</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">NodeJS</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">Java</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★★☆</span>
                </li>
               
                
              </ul>
            </div>
        }
        {pullRequest["number"] == 3 &&
            <div className="skill-category">
              <h2>AWS</h2>
              <ul>
                <li>
                  <span className="skill-name">VPC</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">EC2</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">ECS Fargate</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">Route53</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">RDS</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">DirectConnect/VPN</span>
                  <span className="experience">1年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
              </ul>
            </div>
        }
        {pullRequest["number"] == 4 &&
            <div className="skill-category">
              <h2>Framework</h2>
              <ul>
                <li>
                  <span className="skill-name">Laravel</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">ReactJS</span>
                  <span className="experience">2年以上</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">Provider</span>
                  <span className="experience">3年</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">Riverpod</span>
                  <span className="experience">1年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
                
              </ul>
            </div>
        }
        {pullRequest["number"] == 5 &&
            <div className="skill-category">
              <h2>DB</h2>
              <ul>
                <li>
                  <span className="skill-name">MySQL</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">PostgreSQL</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★☆</span>
                </li>
                <li>
                  <span className="skill-name">Oracle</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
                <li>
                  <span className="skill-name">DynamoDB</span>
                  <span className="experience">2年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
              </ul>
            </div>
        }
        {pullRequest["number"] == 6 &&
            <div className="skill-category">
              <h2>OS</h2>
              <ul>
                <li>
                  <span className="skill-name">Linux/CentOS</span>
                  <span className="experience">5年以上</span>
                  <span className="rating">★★★★★</span>
                </li>
                <li>
                  <span className="skill-name">Windows</span>
                  <span className="experience">3年</span>
                  <span className="rating">★★★☆☆</span>
                </li>
                <li>
                  <span className="skill-name">MacOS</span>
                  <span className="experience">3年以上</span>
                  <span className="rating">★★★☆☆</span>
                </li>
              </ul>
            </div>
        }
        </div>
      </Fade>
    );
  }
}

export default SkillCard;
